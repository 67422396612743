import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .skiplink {
    text-align: center;
    margin: 17px 0 0 10px;
    line-height: 30px;
    padding: 0 10px;
    border: 2px dashed #107db5;
    border-radius: 4px;
    background: #fff;
    z-index: 11;
    position: absolute;
    color: black;
    box-shadow: none;
    top: -70px;
    width: 1px;
    height: 1px;
    overflow: hidden;

    clip: rect(0, 0, 0, 0);

    &:focus {
      top: 0;
      clip: initial;
      width: initial;
      height: initial;
      outline-color: transparent;
    }

    &::after {
      display: none;
    }
  }
`;

const SkipLink = () => (
  <>
    <a className={styles.skiplink} href="#main">
      Skip to content
    </a>
    <a className={styles.skiplink} href="#footer">
      Skip to footer
    </a>
  </>
);

export default SkipLink;

import { css } from 'astroturf';
import React from 'react';
import Link from './link';

const styles = css`
  .topbar {
    position: relative;
    text-align: center;
    line-height: 1.2;
    background: #000;
    color: #fff;
    padding: 0.5rem;
    font-weight: 500;
    overflow: hidden;
    z-index: 2;
    display: block;
    height: auto;
    margin: 0;

    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    @media (min-width: 750px) {
      padding: 0;
      display: block;
      height: 40px;
      line-height: 38px;
    }
    & span {
      background: #fff;
      color: #000;
      padding: 0 0.5rem;
      border-radius: 8px;
      margin: 0 0.25rem;
      font-size: 0.8rem;
      line-height: 1.2rem;
      position: relative;
      vertical-align: middle;
      display: inline-block;
      height: 1.2rem;
      transition: 0.25s ease-in-out box-shadow;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      margin-top: -1.5px;
    }
  }
`;

const getCoupon = () => {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.coupon;
  }

  return false;
};

function Topbar() {
  return getCoupon() ? (
    <Link to="/pricing/" className={styles.topbar}>
      <p>
        The coupon <span>{sessionStorage.coupon}</span>
        {!isNaN(parseInt(sessionStorage.coupon.substr(sessionStorage.coupon.length - 2))) ? (
          <>for {sessionStorage.coupon.substr(sessionStorage.coupon.length - 2)}% off </>
        ) : null}
        will automatically be applied during checkout.
      </p>
    </Link>
  ) : null;
}

export default Topbar;

import { DocSearch } from '@docsearch/react';
import { css } from 'astroturf';
import React, { useEffect, useState } from 'react';

import '@docsearch/css';

const translations = {
  button: {
    buttonText: 'Search',
    buttonAriaLabel: 'Search',
  },
  modal: {
    searchBox: {
      resetButtonTitle: 'Clear the query',
      resetButtonAriaLabel: 'Clear the query',
      cancelButtonText: 'Cancel',
      cancelButtonAriaLabel: 'Cancel',
    },
    startScreen: {
      recentSearchesTitle: 'Recent',
      noRecentSearchesText: 'No recent searches',
      saveRecentSearchButtonTitle: 'Save this search',
      removeRecentSearchButtonTitle: 'Remove this search from history',
      favoriteSearchesTitle: 'Favorite',
      removeFavoriteSearchButtonTitle: 'Remove this search from favorites',
    },
    errorScreen: {
      titleText: 'Unable to fetch results',
      helpText: 'You might want to check your network connection.',
    },
    footer: {
      selectText: 'to select',
      selectKeyAriaLabel: 'Enter key',
      navigateText: 'to navigate',
      navigateUpKeyAriaLabel: 'Arrow up',
      navigateDownKeyAriaLabel: 'Arrow down',
      closeText: 'to close',
      closeKeyAriaLabel: 'Escape key',
      searchByText: 'Search by',
    },
    noResultsScreen: {
      noResultsText: 'No results for',
      suggestedQueryText: 'Try searching for',
      reportMissingResultsText: 'Believe this query should return results?',
      reportMissingResultsLinkText: 'Let us know.',
    },
  },
};

const isThisMac = () => /Mac|iPod|iPhone|iPad/.test(navigator.platform);

const styles = css`
  .docwrapper {
    margin-right: 1rem;

    @media (max-width: 1200px) {
      margin-right: 0;
    }
    @media (max-width: 30em) {
      margin-right: 2.5rem;
    }
  }

  :global(:root) {
    --docsearch-primary-color: rgb(97, 97, 97);
    --docsearch-text-color: rgb(51, 51, 56);
    --docsearch-icon-stroke-width: 2;
    --docsearch-spacing: 16px;
    --docsearch-container-background: #000000bf;
    --docsearch-logo-color: #fff;
    --docsearch-modal-background: #fff;
    --docsearch-searchbox-height: 56px;
    --docsearch-searchbox-background: #fff;
    --docsearch-searchbox-focus-background: #fff;
    --docsearch-searchbox-shadow: none;
    --docsearch-hit-shadow: none;
    --docsearch-hit-background: #fafafa;
    --docsearch-hit-height: 44px;
    --docsearch-logo-color: rgb(51, 51, 56);
    --docsearch-highlight-color: #2c82ad;
    --docsearch-key-gradient: none;
    --docsearch-key-shadow: none;
    --docsearch-footer-shadow: none;
  }
  :global(.DocSearch-Container) {
    transform: translateZ(1010px);
  }
  :global(.DocSearch-Modal) {
    border-radius: 8px;
    @media (max-width: 768px) {
      border-radius: 0;
    }
  }
  :global(.DocSearch-Hit-source) {
    color: var(--docsearch-text-color);
  }

  :global(.DocSearch-Hit-title) {
    font-weight: normal;
  }
  :global(.DocSearch-Cancel) {
    padding-right: var(--docsearch-spacing);
  }

  :global(.DocSearch a) {
    margin: 0;
    padding: 0 8px;
    background: none;
  }
  :global(.DocSearch-Hit) {
    padding-bottom: 6px;
  }
  :global(.DocSearch-Hit a) {
    background: var(--docsearch-hit-background);
  }

  :global(.DocSearch-Button::after) {
    content: 'Ctrl K';
    font-weight: 600;
    font-size: 0.875em;
    display: block;
    padding-right: 8px;

    @media (max-width: 1200px) {
      content: '';
      padding: 0;
    }
  }

  :global([data-ismac='true'] .DocSearch-Button::after) {
    content: '⌘K';
    @media (max-width: 1200px) {
      content: '';
      padding: 0;
    }
  }

  :global([menuvisible] .DocSearch-Button .DocSearch-Search-Icon) {
    color: #fff;
  }
  :global(.DocSearch-MagnifierLabel svg),
  :global(.DocSearch-Button .DocSearch-Search-Icon),
  :global(.DocSearch-Reset svg),
  :global(.DocSearch-Hit-icon svg),
  :global(.DocSearch-Hit-action svg) {
    height: 16px;
  }
  :global(.DocSearch-MagnifierLabel svg),
  :global(.DocSearch-Button .DocSearch-Search-Icon) {
    color: var(--docsearch-highlight-color);
  }

  :global(.DocSearch-Button-Placeholder) {
    font-size: 0.9em;
    line-height: 1;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  :global(.DocSearch-Button),
  :global(.DocSearch-Button:hover) {
    margin-left: 0;
    box-shadow: inset 0 0 0 1px rgb(97 97 97 / 0.2);
    background: #fff8;

    @media (max-width: 1200px) {
      box-shadow: none;
      background: none;
    }
  }

  [data-dark='true'] :global(.DocSearch-Button),
  [data-dark='true'] :global(.DocSearch-Button:hover) {
    background: #000f;
  }
  :global(.DocSearch-Button .DocSearch-Search-Icon) {
    opacity: 0.5;
    width: 20px;
    height: 20px;
  }

  :global(.DocSearch-SearchBar) {
    padding: 0;
    border-bottom: 1px solid rgb(97 97 97 / 0.2);
  }

  :global(.DocSearch-Button-Keys) {
    display: none;
  }
  :global(.DocSearch-Footer) {
    border-top: 1px solid rgb(97 97 97 / 0.2);
  }
  :global(.DocSearch-Footer .cls-1),
  :global(.DocSearch-Footer .cls-2) {
    fill: var(--docsearch-highlight-color);
  }
`;

const DocSearchWrapper = () => {
  const [isMac, setIsMac] = useState(false);

  useEffect(() => {
    setIsMac(isThisMac());
  }, []);

  return (
    <div className={styles.docwrapper} data-ismac={isMac}>
      <DocSearch
        translations={translations}
        placeholder="Search documentation"
        appId="W6R2022YTT"
        indexName="polypane"
        apiKey="4517b4e0a210193ee902f3e5a5cd535e"
      />
    </div>
  );
};

export default DocSearchWrapper;

import { css } from 'astroturf';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight, Close, Menu } from 'react-bytesize-icons';

import useOnClickOutside from './header/useOnClickOutside';

import DocSearch from './DocSearch';
import SkipLink from './SkipLink';
import TopBar from './Topbar';
import Releases from './header/Releases';
import BrandKit from './header/BrandKit';
import Link from './link';
import AccountLink from './header/AccountLink';
import Logo from '../../assets/images/icon.svg';

const styles = css`
  .logo {
    position: relative;
    z-index: 10;
    background: linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 34px;
    line-height: 70px;
    margin: 0;
    padding: 0;
    height: 70px;
    width: 200px;
    text-decoration: none;
    box-shadow: initial;
    white-space: nowrap;
    font-feature-settings: 'ss01', 'cv05', 'ss03', 'zero', 'cv11';

    & svg {
      overflow: visible;
      height: 32px;
      width: 32px;
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 0.5rem;
      transform: translateY(-5px);
    }

    &:hocus {
      text-decoration: none;
    }
  }
  .header {
    line-height: 70px;
    position: sticky;
    left: 0;
    width: 100%;
    top: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 10;
    box-shadow: 0 0 0 rgba(0, 40, 100, 0);
    transition: 0.25s ease-in-out all;
    transform: translateZ(1000px);
    --normal: currentColor;
    --hover: rgb(0, 121, 177);

    &.dark {
      --normal: #fffa;
      --hover: #fff;
    }

    @media (forced-colors: active) {
      border-bottom: 1px solid;
    }

    &::before {
      z-index: -2;
      display: block;
      content: ' ';
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgb(247, 250, 255), rgba(247, 250, 255, 0));
      pointer-events: none;
      transition: backdrop-filter 0.25s ease-out;
    }

    &.dark::before {
      backdrop-filter: blur(4px) saturate(200%);
      background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    }

    &::after {
      pointer-events: none;
      z-index: -1;
      display: block;
      content: ' ';
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      background: hsla(0, 0%, 100%, 0.66667);
      box-shadow:
        rgba(0, 0, 0, 0.02) 0px 1px 0px,
        rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
      opacity: 0;

      transition: 0.25s ease-out opacity;
    }

    &.dark::after {
      opacity: 1;
      background: hsla(0, 0%, 0%, 0.66667);
    }

    &[scrolled]::after {
      opacity: 1;
    }
    &[scrolled]::before {
      backdrop-filter: blur(4px) saturate(200%);
    }

    & nav {
      margin: 0 auto;
    }

    @media (max-width: 56em) {
      &[menuvisible] {
        & .menuButton svg {
          padding: 4px;
          stroke: #fff;
          transition: stroke 0.2s ease-in-out;
        }
        & .menuButton::before {
          opacity: 1;
          transform: scale(1, 1);
          transition:
            0.25s ease-in-out transform,
            0.1s ease-in-out opacity;
        }
        & > a:first-child,
        & .logo {
          color: #fff;
          -webkit-background-clip: none;
          -webkit-text-fill-color: #fff;
          transition: -webkit-text-fill-color 0.25s 0s ease-in-out;
        }
        & nav ul {
          opacity: 1;

          height: 100vh;

          & li {
            pointer-events: all;
            transition: 0.25s 0.05s ease-in-out all;
            opacity: 1;
            transform: translateY(0);

            & a {
              background: none;
            }
          }
          & li + li {
            transition-delay: 0.15s;
          }
          & li + li + li {
            transition-delay: 0.2s;
          }
          & li + li + li + li {
            transition-delay: 0.25s;
          }
          & li + li + li + li + li {
            transition-delay: 0.3s;
          }
        }
      }
    }

    & > .inner {
      padding: 0;
      padding-left: env(safe-area-inset-left, 0);
      padding-right: env(safe-area-inset-right, 0);
      height: 70px;
      position: relative;
      width: 94vw;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      @media (min-width: 56em) {
        justify-content: flex-start;
      }
    }
  }

  .menu {
    @media (max-width: 56em) {
      z-index: 2;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      color: #fff;
      margin: 0;
      padding: 100px 0;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-around;

      opacity: 0;
      pointer-events: none;
      transition: 0.75s ease-in-out all;

      & li {
        list-style: none;
        transform: translateY(-20px);
        opacity: 0;
        transition:
          0.25s 0s ease-in-out opacity,
          0.1s 0.5s linear transform;
        font-size: 1.5rem;
        line-height: 2;
        & svg {
          display: none;
        }
        & a {
          color: #fff;
          display: block;
          text-align: center;
          font-weight: 500;
          text-decoration: none;
          white-space: nowrap;
          margin: 0;
          padding: 0;

          &:focus-visible {
            outline-color: currentColor;
            outline-offset: 4px;
          }
        }

        &.buttonholder {
          display: none;
        }
      }
    }
    @media (min-width: 56em) {
      display: flex;
      line-height: 70px;
      padding-top: 0;
      padding-left: 1rem;
      margin: 0 auto;

      & li {
        position: relative;
        list-style: none;
        padding-left: 1rem;
        padding-top: 0px;

        &:hover {
          z-index: 1;
        }

        &.linkholder {
          display: none;
        }

        & svg {
          display: inline-block;
          vertical-align: middle;
        }
        & p {
          font-size: 0.875em;
          padding-right: 1rem;
        }
        & button,
        & a {
          font-weight: 500;
          box-shadow: initial;
          background: none;
          white-space: nowrap;
          transition: 0.25s ease-in-out color;
          font-size: 0.875em;
          color: var(--normal);

          &:hover {
            color: var(--hover);
            background: none;
          }
        }

        & button {
          border: none;
          cursor: pointer;
          & svg {
            transition: 0.35s ease-in-out transform;
          }
          &.active {
            color: var(--hover);
            & svg {
              transform: rotate(-180deg);
            }
          }
        }

        & .activeMenu {
          color: var(--hover);
        }
      }
    }
    @media (min-width: 1024px) {
      & li {
        padding-left: 2.5rem;
      }
    }
  }

  .buttonWrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 48px);
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    padding-top: 19px;
    padding-right: 3vw;
    pointer-events: none;
  }

  .menuButton {
    pointer-events: all;
    outline: none;
    position: relative;
    border: 0;
    height: 32px;
    width: 32px;
    background: none;
    color: #107db5;
    font-size: 0rem;
    display: none;

    & svg {
      pointer-events: none;
      stroke: currentcolor;
      vertical-align: middle;
      height: 32px;
      width: 32px;
      transition: 0.2s 0.5s stroke ease-in-out;
    }

    &::before {
      display: block;
      content: ' ';
      position: absolute;
      border-radius: 200vh;
      opacity: 0;
      left: calc(-130vmax + 50%);
      top: calc(-130vmax + 50%);
      width: 260vmax;
      height: 260vmax;
      background: #107db5;
      display: block;
      z-index: -1;
      transform: scale(0, 0);
      transform-origin: 50% 50%;
      transition:
        0.5s ease-in-out transform,
        0.2s 0.4s ease-in-out opacity;
    }
    @media (max-width: 56em) {
      display: block;
    }
  }

  .rightside {
    margin: 0 0 0 auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  a.button {
    position: relative;
    outline: none;
    align-self: center;
    z-index: 2;
    border: 0;
    height: 35px;
    line-height: 35px;
    border-radius: 40px;
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    white-space: nowrap;
    opacity: 1;
    padding: 0;
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    background-image: linear-gradient(135deg, oklab(0.61 -0.08 -0.21), oklab(0.66 -0.24 0.16));
    margin: 0 0 0;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    & i {
      display: block;
      height: 35px;
      line-height: 35px;
      border-radius: 40px;
      padding: 0 1rem;
      transition: 0.25s ease-out background;
      background: #0a1530;
      font-style: normal;

      @media (forced-colors: active) {
        border: 1px solid;
      }

      & svg {
        vertical-align: middle;
        margin-left: 10px;
        margin-top: -1px;
      }
    }

    &:hocus {
      & i {
        background: transparent;
      }
      text-decoration: none;
    }

    @media (max-width: 56rem) {
      display: none;
    }
  }

  .active + .dropdown {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0px);
    transition:
      opacity 0.35s ease-in-out,
      transform 0.35s ease-in-out;
  }

  .dropdown {
    --white: #fff;
    --fill: #0a1530;
    position: absolute;
    left: 0;
    top: 60px;
    text-align: left;
    color: #000;
    background: #fffffff1;
    backdrop-filter: blur(5px) brightness(200%) saturate(200%);
    border-radius: 0.5rem;
    box-shadow:
      rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    display: flex;
    flex-direction: column;
    width: 28rem;
    justify-content: flex-start;
    align-items: stretch;
    line-height: 1.75;
    white-space: initial;

    pointer-events: none;
    opacity: 0;
    transform-style: preserve-3d;
    transform-origin: top center;
    transform: translateY(-10px);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;

    @media (max-width: 56em) {
      display: none;
    }

    @media (min-width: 72rem) {
      flex-direction: row;
      width: 44rem;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: -20px;
      left: 53px;
      border: 10px solid #fffe;
      border-color: transparent transparent #fffe transparent;
    }

    &::after {
      content: ' ';
      z-index: -1;
      position: absolute;
      width: calc(100% + 80px);
      height: calc(100% + 40px);
      top: -15px;
      left: -40px;
    }

    & h3 {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: var(--fill);
    }

    & .mainblock {
      flex: 2 0 60%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;

      @media (min-width: 72rem) {
        padding: 2rem 1rem 2rem 1rem;

        & h3 {
          padding-left: 0;
        }
      }

      & h3 {
        padding-left: 0.75rem;
        padding-bottom: 0.5rem;
      }

      & a {
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        background: none;
        padding: 0.5rem 1rem;
        gap: 0.5rem;
        color: #0a1530aa;
        transition:
          color 0.25s ease-in-out,
          background 0.25s ease-in-out;

        @media (min-width: 72rem) {
          padding: 1rem;
          gap: 1.5rem;

          &:last-child {
            margin-bottom: -1rem;
          }
        }

        &:hover,
        &:focus {
          color: var(--fill);
          background: rgba(16, 125, 181, 0.1);

          & b svg {
            opacity: 1;
            transform: translateY(-1px) translateX(0);
          }
        }

        & b {
          display: block;
          color: var(--fill);

          & svg {
            display: inline;
            vertical-align: middle;
            margin-left: 0.5rem;
            transition:
              opacity 0.25s ease-in-out,
              transform 0.25s ease-in-out;
            transform: translateY(-1px) translateX(-0.5rem);
            opacity: 0;
          }
        }

        & svg {
          flex: 0 0 64px;
          display: block;
          vertical-align: middle;
          margin-right: 0.5rem;
          @media (min-width: 72rem) {
            margin-right: 1.5rem;
          }
        }

        & span {
          flex: 1 1 auto;
          white-space: initial;
          max-width: 270px;
        }
      }
    }

    &.testimonials {
      width: 36rem;
      left: -12.4rem;

      &::before {
        left: calc(50% - 10px);
      }

      & .mainblock {
        padding: 1rem;
        gap: 0.5rem;
        flex-direction: row;
        & a {
          flex-direction: column;
          text-align: center;
          gap: 0.25rem;
          flex: 1 1 auto;

          & svg {
            flex: 0 0 48px;
            margin-right: 0;
          }

          & b {
            white-space: nowrap;
          }

          &:last-child {
            margin-bottom: 0rem;
          }
        }
      }
    }

    &.branding {
      width: 15rem;
      text-align: left;
      white-space: nowrap;
      display: block;

      &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }
    }

    & .sideblock {
      overflow: hidden;
      flex: 1 0 40%;
      background: rgba(16, 125, 181, 0.1);
      border-radius: 0 0 0.5rem 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      padding: 1rem 1rem 1rem 1.75rem;
      gap: 0.5rem;

      & h3 {
        padding-bottom: 0.5rem;
      }

      & p {
        padding-top: 1.2rem;
        line-height: 1.8;
      }
      & div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 0.25rem;

        &:first-child {
          display: none;
          flex: 1 1 auto;
        }

        &:last-child {
          flex: 0 1 auto;
        }
      }

      & div + div h3 {
        padding-bottom: 1rem;
      }

      @media (min-width: 72rem) {
        padding: 2rem 1rem 2rem 2rem;
        border-radius: 0 0.5rem 0.5rem 0;
        flex-direction: column;
        gap: 2rem;

        & div:first-child {
          display: block;
        }
      }

      & a {
        color: #0071a6;

        &:focus,
        &:hover {
          color: var(--fill);
          & svg {
            opacity: 1;
            transform: translateY(-1px) translateX(0);
          }
        }
        & svg {
          vertical-align: middle;
          margin-left: 0.5rem;
          transition:
            opacity 0.25s ease-in-out,
            transform 0.25s ease-in-out;
          transform: translateY(-1px) translateX(-0.5rem);
          opacity: 0;
        }
      }
      & a + h3 {
        padding-top: 2rem;
      }
    }
  }

  .badge {
    display: inline-block;
    font-size: 0.5rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
    margin-left: 0.5rem;
    vertical-align: baseline;
    letter-spacing: 0.05rem;
  }
`;

function Header(props) {
  const [menuvisible, setMenuVisible] = useState(false);
  const [brandingvisible, setBrandingVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [active, setActive] = useState(false);
  const [testimonialsActive, setTestimonialsActive] = useState(false);
  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const menuBtnHandler = (e) => {
    e.preventDefault();
    setMenuVisible(!menuvisible);
    document.querySelector('#menu a').focus();
  };

  const setScrolledFunc = () => {
    setScrolled(window.scrollY > 20);
  };

  useEffect(() => {
    setScrolledFunc();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', setScrolledFunc, { passive: true });
    return function cleanup() {
      window.removeEventListener('scroll', setScrolledFunc, { passive: true });
    };
  });

  useOnClickOutside(ref, () => setActive(false));
  useOnClickOutside(ref2, () => setTestimonialsActive(false));
  useOnClickOutside(ref3, () => setBrandingVisible(false));

  return (
    <header
      className={[styles.header, props.dark ? styles.dark : ''].join(' ')}
      menuvisible={menuvisible ? 'active' : undefined}
      scrolled={scrolled ? 'active' : undefined}
      data-dark={props.dark}
    >
      <SkipLink />
      {!props.noBar && <TopBar page={props.page} />}
      <div className={styles.inner}>
        <Link
          to="/"
          className={styles.logo}
          aria-controls="branding"
          aria-expanded={menuvisible}
          onContextMenu={(e) => {
            e.preventDefault(); // prevent the default behaviour when right clicked
            setBrandingVisible(!brandingvisible);
          }}
        >
          <Logo />
          Polypane
        </Link>

        <div
          ref={ref3}
          id="branding"
          aria-hidden={!brandingvisible}
          className={[styles.dropdown, styles.branding, brandingvisible ? styles.active : ''].join(' ')}
          inert={!brandingvisible ? '' : null}
        >
          <BrandKit />
        </div>

        <nav aria-label="Primary">
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              className={styles.menuButton}
              onClick={menuBtnHandler}
              aria-controls="menu"
              aria-expanded={menuvisible}
            >
              {menuvisible ? (
                <Close width={16} height={16} color="#fff" />
              ) : (
                <Menu width={16} height={16} color="#fff" />
              )}
              Menu
            </button>
          </div>
          <ul id="menu" role="list" className={styles.menu}>
            <li className={styles.linkholder}>
              <Link activeClassName={styles.activeMenu} to="/product-tour/">
                Features
              </Link>
            </li>
            <li className={styles.buttonholder} ref={ref}>
              <button
                type="button"
                className={active ? styles.active : null}
                onClick={(e) => {
                  setActive(!active);
                  setTestimonialsActive(false);
                  e.target.nextSibling?.querySelector('a').focus();
                }}
                aria-controls="dropdown"
                aria-expanded={active}
              >
                Features{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </button>

              <div id="dropdown" inert={!active ? '' : null} className={styles.dropdown}>
                <div className={styles.mainblock}>
                  <h3>Everything you need</h3>
                  <Link to="/product-tour/">
                    <svg viewBox="0 0 103 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="53" height="60" rx="8" fill="url(#gradient)" />
                      <rect x="60" width="43" height="50" rx="8" fill="url(#gradient)" />
                    </svg>
                    <span>
                      <b>
                        Responsive Design <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                      </b>
                      Mobile screens to 5K monitors, get all viewports in one overview.
                    </span>
                  </Link>
                  <Link to="/accessibility/">
                    <svg viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="gradient" gradientTransform="rotate(45)">
                          <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                          <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
                        </linearGradient>
                      </defs>
                      <rect x="0.5" width="87" height="60" rx="10" fill="url(#gradient)" />
                      <path
                        d="M22.5 18.5C22.5 21 24.5 23 27 23C29.4375 23 31.5 21 31.5 18.5C31.5 16.0625 29.4375 14 27 14C24.5 14 22.5 16.0625 22.5 18.5ZM38.375 18.625C37.625 17.8125 36.3125 17.8125 35.5625 18.625L30.125 24H23.8125L18.375 18.625C17.625 17.8125 16.3125 17.8125 15.5625 18.625C14.75 19.375 14.75 20.6875 15.5625 21.4375L21.5 27.375V44C21.5 45.125 22.375 46 23.5 46H24.5C25.5625 46 26.5 45.125 26.5 44V37H27.5V44C27.5 45.125 28.375 46 29.5 46H30.5C31.5625 46 32.5 45.125 32.5 44V27.375L38.375 21.4375C39.1875 20.6875 39.1875 19.375 38.375 18.625Z"
                        fill="var(--white)"
                      />
                      <path
                        d="M54.5 18.5C54.5 21 56.5 23 59 23C61.4375 23 63.5 21 63.5 18.5C63.5 16.0625 61.4375 14 59 14C56.5 14 54.5 16.0625 54.5 18.5ZM70.375 18.625C69.625 17.8125 68.3125 17.8125 67.5625 18.625L62.125 24H55.8125L50.375 18.625C49.625 17.8125 48.3125 17.8125 47.5625 18.625C46.75 19.375 46.75 20.6875 47.5625 21.4375L53.5 27.375V44C53.5 45.125 54.375 46 55.5 46H56.5C57.5625 46 58.5 45.125 58.5 44V37H59.5V44C59.5 45.125 60.375 46 61.5 46H62.5C63.5625 46 64.5 45.125 64.5 44V27.375L70.375 21.4375C71.1875 20.6875 71.1875 19.375 70.375 18.625Z"
                        fill="var(--white)"
                      />
                    </svg>
                    <span>
                      <b>
                        Accessibility <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                      </b>
                      Your site should work for everyone. Polypane gives you the tools.
                    </span>
                  </Link>
                  <Link to="/site-quality/">
                    <svg viewBox="0 0 111 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="111" height="60" rx="10" fill="url(#gradient)" />
                      <rect x="6" y="6" width="49" height="48" rx="6" fill="var(--white)" />
                      <rect x="16" y="17" width="29" height="3" fill="url(#gradient)" />
                      <rect x="16" y="25" width="13" height="3" fill="url(#gradient)" />
                      <rect x="16" y="33" width="21" height="3" fill="url(#gradient)" />
                      <rect x="16" y="41" width="16" height="3" fill="url(#gradient)" />
                      <rect x="68" y="17" width="29" height="3" fill="var(--white)" />
                      <rect x="68" y="25" width="13" height="3" fill="var(--white)" />
                      <rect x="68" y="33" width="21" height="3" fill="var(--white)" />
                      <rect x="68" y="41" width="16" height="3" fill="var(--white)" />
                    </svg>
                    <span>
                      <b>
                        Site Quality <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                      </b>
                      Give users the best experience on every device and network.
                    </span>
                  </Link>
                  <Link to="/portal/">
                    <svg viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="88" height="60" rx="10" fill="url(#gradient)" />
                      <g transform="translate(20 6) scale(2)">
                        <path d="M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" strokeWidth="2" stroke="var(--white)" />
                        <path d="M12 21c-3.314 0 -6 -2.462 -6 -5.5s2.686 -5.5 6 -5.5" stroke="var(--white)" />
                        <path d="M21 12c0 3.314 -2.462 6 -5.5 6s-5.5 -2.686 -5.5 -6" stroke="var(--white)" />
                        <path d="M12 14c3.314 0 6 -2.462 6 -5.5s-2.686 -5.5 -6 -5.5" stroke="var(--white)" />
                        <path d="M14 12c0 -3.314 -2.462 -6 -5.5 -6s-5.5 2.686 -5.5 6" stroke="var(--white)" />
                      </g>
                    </svg>
                    <span>
                      <b>
                        Portal
                        <Badge>Dark mode page</Badge> <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                      </b>
                      Test your site across multiple browsers, devices and networks.
                    </span>
                  </Link>
                </div>

                <div className={styles.sideblock}>
                  <div>
                    <h3>For Who</h3>
                    <p>For developers, designers, QA and marketers looking to improve their workflow.</p>
                  </div>
                  <div className={styles.integrations}>
                    <h3>More features</h3>
                    <Link to="/social-media-previews/">
                      Social media previews <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                    <Link to="/quality-assurance/">
                      Quality Assurance <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                    <Link to="/tailwindcss/">
                      Tailwind CSS <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                    <Link to="/integrations/">
                      Integrations <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                  </div>
                  <div>
                    <h3>Free Online Tools</h3>
                    <Link to="/color-contrast/">
                      Color Contrast Checker <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                    <Link to="/css-specificity-calculator/">
                      CSS Specificity Calculator <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                    <Link to="/experimental-web-platform-features/">
                      Experimental Platform features <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link activeClassName={styles.activeMenu} to="/docs/">
                Docs
              </Link>
            </li>
            <li className={styles.linkholder}>
              <Link activeClassName={styles.activeMenu} to="/testimonials/">
                Testimonials
              </Link>
            </li>
            <li className={styles.buttonholder} ref={ref2}>
              <button
                type="button"
                className={testimonialsActive ? styles.active : null}
                onClick={(e) => {
                  setActive(false);
                  setTestimonialsActive(!testimonialsActive);
                  e.target.nextSibling?.querySelector('a').focus();
                }}
                aria-controls="dropdown2"
                aria-expanded={testimonialsActive}
              >
                Customers{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </button>

              <div
                id="dropdown2"
                inert={!testimonialsActive ? '' : null}
                className={[styles.dropdown, styles.testimonials].join(' ')}
              >
                <div className={styles.mainblock}>
                  <Link to="/testimonials/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-award"
                      width="88"
                      height="60"
                      viewBox="0 0 44 30"
                      strokeWidth="0"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    >
                      <rect width="44" height="30" rx="5" fill="url(#gradient)" />
                      <g transform="translate(10 3)">
                        <path
                          d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                          stroke="var(--white)"
                        />
                      </g>
                    </svg>

                    <span>
                      <b>Testimonials</b>
                      Our users love Polypane. Here's what they say.
                    </span>
                  </Link>
                  <Link to="/case-studies/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-award"
                      width="88"
                      height="60"
                      viewBox="0 0 44 30"
                      strokeWidth="0"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    >
                      <rect width="44" height="30" rx="5" fill="url(#gradient)" />
                      <g transform="translate(10 3)">
                        <rect x="3" y="7" width="18" height="13" rx="2" stroke="var(--white)" />
                        <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" stroke="var(--white)" />
                        <line x1="12" y1="12" x2="12" y2="12.01" stroke="var(--white)" />
                        <path d="M3 13a20 20 0 0 0 18 0" />
                      </g>
                    </svg>
                    <span>
                      <b>Case Studies</b>
                      Our customers tell what Polypane did for them.
                    </span>
                  </Link>
                  <Link to="/accolades/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-award"
                      width="88"
                      height="60"
                      viewBox="0 0 44 30"
                      strokeWidth="0"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    >
                      <rect width="44" height="30" rx="5" fill="url(#gradient)" />
                      <g transform="translate(10 3)">
                        <circle cx="12" cy="9" r="6" stroke="var(--white)" />
                        <polyline
                          points="9 14.2 9 21 12 19 15 21 15 14.2"
                          transform="rotate(-30 12 9)"
                          stroke="var(--white)"
                        />
                        <polyline
                          points="9 14.2 9 21 12 19 15 21 15 14.2"
                          transform="rotate(30 12 9)"
                          stroke="var(--white)"
                        />
                      </g>
                    </svg>

                    <span>
                      <b>Accolades</b>
                      Recognition and awards from leading organisations.
                    </span>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <Link activeClassName={styles.activeMenu} to="/blog/">
                Blog
              </Link>
            </li>
            <li>
              <Link activeClassName={styles.activeMenu} to="/pricing/">
                Pricing
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.rightside}>
          <DocSearch />
          <Releases />
          <AccountLink />
          <Link className={[styles.button].join(' ')} to="https://dashboard.polypane.app/register/">
            <i>
              Try for free <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
            </i>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;

const Badge = ({ children }) => <span className={styles.badge}>{children}</span>;

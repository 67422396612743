import React from 'react';
import ReactTooltip from 'react-tooltip';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Policy from '../components/Policy';

const getCoupon = () => {
  if (typeof window !== 'undefined') {
    const params = new URL(document.location).searchParams;
    if (params.get('coupon')) {
      const coupon = params.get('coupon');
      sessionStorage.coupon = coupon;
    }
  }
};
export default class MainLayout extends React.Component {
  render() {
    getCoupon();
    const { children, page, darkHeader, noBar } = this.props;
    return (
      <div style={this.props.background ? { background: this.props.background } : {}}>
        <Header page={page} dark={darkHeader} noBar={noBar}/>
        <main id="main">{children}</main>
        <Footer page={page} />
        <aside>
          <Policy />
        </aside>
        <ReactTooltip
          class="polytip"
          effect="solid"
          type="dark"
          uuid="singulartooltip"
          multiline
          backgroundColor="#0a1530"
          html
        />
      </div>
    );
  }
}
